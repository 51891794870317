import(/* webpackMode: "eager" */ "/vercel/path0/apps/frontend/src/components/atoms/Main/index.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/keyless-cookie-sync.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useReverification","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["PromisifiedAuthProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/PromisifiedAuthProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile","Waitlist"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
